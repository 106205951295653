import PropTypes from 'prop-types';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const Wrapper = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
});

const ShortcutsWrapper = styled('div', {
    width: '80%',
    display: 'flex',

    [media.max['mobile.lg']]: {
        flexDirection: 'column',
        width: '100%',
    },
});

const AccountShortcuts = ({ shortcuts = [] }) => (
    <Wrapper>
        <ShortcutsWrapper>
            {shortcuts.map((shortcut, index) => (
                <ThemeButton
                    $style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '56px',
                        justifyContent: 'center',
                        width: '100%',
                        margin: ['8px 0px', null, null, '0px 8px'],
                        padding: '12px 16px',
                    }}
                    as={Link}
                    fontKeys="Misc/16_100_-1_500_UC"
                    key={index}
                    rel={shortcut.rel}
                    size="md"
                    theme="defaultsecondary"
                    to={shortcut.url}
                >
                    <div>
                        {shortcut.icon && (
                            <Image $style={{ marginRight: '8px', height: '16px', width: '16px' }} src={shortcut.icon} />
                        )}
                    </div>
                    <span>{shortcut.label}</span>
                </ThemeButton>
            ))}
        </ShortcutsWrapper>
    </Wrapper>
);

AccountShortcuts.propTypes = {
    shortcuts: PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        url: PropTypes.string,
    }),
};

export default AccountShortcuts;
